*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root{
  --titlebar: #35363A;
  --addressbar: #4d5153;
  --accentdark:  #282c34;
  --accentyellow: #ffd23e;
  --accenthash: #3e444b;

}
html{
  min-height: 100vh;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: var(--accentdark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
