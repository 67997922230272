.address-bar-container{
    display: flex;
    align-items: center;
    background-color: var(--addressbar);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}
.address-bar{
    width: calc(100% - 150px);
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
}
.address-bar div{
    background-color: #282c34;
    color: #afafaf;
    padding: 5px;
    border-radius: 50px;
    width: 100%;
    font-size: small;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    line-height: normal;
}
.address-bar div span:nth-of-type(1){
    color: rgb(119, 145, 119);
    margin-left: 3px;
}
.address-bar div span:nth-of-type(2){
    color: white;
    font-weight: 600;
}
.address-bar input[type="text"]{
    background-color: #282c34;
    border: none;
    outline: none;
    color: #afafaf;
    padding: 5px;
    border-radius: 50px;
    width: 100%;
}

.address-bar-extension{
    width: 150px;
    font-size: small;
    letter-spacing: normal;
}
.address-bar-extension button{
    padding: 4px;
    margin-right: 10px;
    border-radius:3px;
    border:none;
    outline: none;
    color: white;
    cursor: pointer;
    width: calc(100% - 10px);
    background-color: transparent;
}
.address-bar-extension button:nth-of-type(2){
    display: none;
}
.address-bar-extension button:hover{
    background-color: #636363;
}

@media screen and (max-width:600px){
    .address-bar-extension button:nth-of-type(1){
        display: none;
    }
    .address-bar-extension button:nth-of-type(2){
        display: inline-block;
        font-size:0.7rem;

    }
    .address-bar-extension{
        width: 70px;
    }
    .address-bar{
        width: calc(100% - 70px);
    }
  }