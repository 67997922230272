/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  margin-left: 15px;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 1px;
  background-color: rgb(184, 184, 184);
  top: 0px;
  bottom: 0px;
  left: 8px;
  /* margin-left: -3px; */
}

/* Container around content */
.tcontainer {

  padding: 10px 30px;
  position: relative;
  background-color: inherit;
  /* background-color: yellow; */
  width: 100%;
  font-size: small;
}

/* The circles on the timeline */
.tcontainer::after {
  content: '';
  position: absolute;
  width: 3px;
  height: 3px;
  /* right: -17px; */
  background-color: #ffd23e;
  border: 4px solid #ffd23e;
  top: 50%;
  border-radius: 50%;
  z-index: 1;
}


/* Fix the circle for containers on the right side */
.right::after {
  left: 3px;
}


