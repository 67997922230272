a:link {
    text-decoration: inherit !important;
    color: inherit !important;
}

a:visited {
    text-decoration: inherit !important;
    color: inherit !important;
}

.profile{
    border: 1px solid var(--accenthash);
    padding: 10px;
    margin-top: 40px;
    background-color: var(--accentdark);
}

.profile .s-row{
    display: flex;
    font-size: small;
    margin-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    margin-top: 10px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #3e444b;
}
.profile .l-s-row{
    border-bottom: none !important;
}

.profile .s-row .s-col1{
    width: 20%;
    display: flex;
    align-items: center;
    background-color: var(--accenthash);
    /* width: 100%; */
    border-radius: 3px;

}
.profile .s-row .s-col1 span:nth-of-type(1){
    padding: 5px;
    background-color: #ffd23e;
    font-weight: 700;
    color: #ffd23e;
    color: #242320;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}
.profile .s-row .s-col1 span:nth-of-type(2){
    padding: 5px;
    padding-left: 10px;
    font-weight: 600;
    color: white;
    /* display: none; */
}
.profile .s-row .s-col2{
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    
}
.profile .s-row .s-col2 span{
    /* background-color: var(--addressbar); */
    margin: 2px 0px;
    padding: 3px 15px;
    border-radius: 3px;
    /* background-color: #aaa; */
    /* color: #222; */
    font-weight: 500;
    /* width: 100%; */
}

@media screen and (max-width:800px){
    .profile h2{
        margin-bottom: 20px;
    }
    .profile .s-row{
        margin-top: 10px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #3e444b;
    }
    .profile .s-row .s-col1{
        width: 10%;
        background-color: transparent;
    }
    .profile .s-row .s-col1  span:nth-of-type(2){
        display: none;
    }
    .profile .s-row .s-col2{
        width: 90%;
        display: flex;
        flex-wrap: wrap;
    }
    .profile .s-row .s-col2 span{
        background-color: transparent;
        margin: 0px 0px 0px 0px;
        padding: 3px 5px;
        border-radius: 3px;
    }
    
  }