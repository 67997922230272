.project-card{
    /* background-color: var(--accenthash); */
    /* color:#cccccc; */
    padding: 20px;
    border-radius: 3px;
    border: 1px solid #39393d;
    border: 1px solid var(--accenthash);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    margin-bottom: 30px;
    overflow: hidden;
}

.project-card h3{
    /* background-color: tomato; */
    color: var(--accentyellow);
    /* text-shadow: white; */
}
.project-card .project-description{
    margin-top: 10px;
    font-size: small;
    text-align: justify;
    color: #ddd;
    letter-spacing: 0.05rem;
}
.project-card .project-card-languages{
    margin-top: 10px;
    font-size: 0.8rem;
    /* text-align: justify; */
    /* color: #ddd; */
    /* letter-spacing: 0.05rem; */
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}
.project-card .project-card-languages span{
    padding: 5px 10px;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 25px;
    font-weight: 700;
    /* background-color: #ccc; */
    font-weight: 600;
    background-color: var(--accenthash);
    /* border:1px solid var(--accentyellow); */
    /* color: var(--accentdark); */
    /* color: var(--accentyellow); */
}
.project-card .project-technologies{
    margin-top: 10px;
    font-size: 0.7rem;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}
.project-card .project-technologies span{
    padding: 3px 5px;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 3px;
    font-weight: 700;

    background-color: var(--accentyellow);
    color: var(--accentdark);
    background-color: var(--accenthash);
    background-color: #999;
    color: var(--accentdark);
    background-color: #ccc;
    color: var(--accentdark);
    font-weight: 700;
}
.project-card .project-card-footer div{
    color: var(--accentyellow);
    background-color: var(--accentdark);
    padding: 2px 5px;
    padding-bottom: 12px;
    padding-right: 15px;
    font-size: 0.8rem;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid var(--accentyellow);
    margin-right: -30px;
    margin-bottom: -30px;
    color: var(--accentdark);
    background-color: var(--accentyellow);
    
}
.project-card .project-card-footer span:hover{
    
}
.project-card .project-card-footer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}