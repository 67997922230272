.education{
    /* background-color: #3e444b; */
    border: 1px solid #3e444b;
    padding: 10px;
    margin-top: 40px;
    background-color: #282c34;

}


@media screen and (min-width:1000px){
    .education{
        /* padding: 50px 200px; */
    }
  }