
.window{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    border: 3px solid var(--titlebar);
    width: 80%;
    margin-top: -50px;
    border-radius: 5px;
    height: 80vh;
    overflow: hidden;
    background-color: var(--accentdark);
  }


.window-content{
    padding: 20px 40px 100px 40px;
    height: calc(100%);
    overflow-y: scroll;
}

html {
    --scrollbarBG: #272a2b;
    --thumbBG: #686868;
  }
  .window-content::-webkit-scrollbar {
    width: 11px;
  }
  .window-content {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  .window-content::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  .window-content::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }

  @media screen and (max-width: 800px) {
    .window{
        margin-top: -60px;
        height: 85vh;
        width: 95%;
    }
    .window-content{
        padding: 10px;
        height: calc(100% - 50px);
    }
  }

  @media screen and (min-width:1140px){
    .window-content{
        margin: 0 auto;
    }
    .window-container{
        width: 800px;
        margin: 0 auto;
    }
  }