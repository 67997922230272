.window .window-title-bar .title-tabs{
    margin-left: 15px;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
}
.window .window-title-bar .title-tabs button{
    margin-top: 0px;
    /* margin-left: -5px; */
    height: 100% !important;
    background-color: transparent;
    outline: none;
    border: none;
    width: 120px;
    /* border-top: 1px solid #555;
    border-left: 1px solid #555;
    border-right: 1px solid #555;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
    color:white;
    cursor: pointer;

    border-bottom: 25px solid #282c34;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    /* height: 0; */
    width: 120px;
    line-height: 25px;
}

.window .window-title-bar .title-tabs .active-tab{
    border-bottom: 25px solid #282c34;
    border-bottom: 25px solid #6d6d6d63;
    border-bottom: 25px solid #35363A;
    border-bottom: 25px solid var(--addressbar);
}

@media screen and (max-width: 600px) {
    .window .window-title-bar .title-tabs button{
        width: 80px;
    }
  }