.experience-timeline-card {
    /* display: flex; */
    /* align-items: stretch; */
    position: relative;
    /* background-color: #282c34; */

}

.experience-timeline-card div {
    padding: 2px 5px;
    width: fit-content;

}

.experience-timeline-card div:nth-of-type(1) {
    background-color: #ffd23e;
    color: black;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 700;
}

.experience-timeline-card div:nth-of-type(2) {
    margin-top: 5px;
    background-color: var(--addressbar);
    color: white;
}