.contact{
    padding: 15px;
    /* min-height: 100%; */
}

.contact .sent{
    margin-top: 30%;
    text-align: center;
    color: var(--accentyellow);
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contact .sent button{
    margin-top: 20px;
    background-color: var(--accentyellow);
    width: 150px;
    height: 30px;
    border: none;
    outline: none;
    border-radius: 3px;
    color:var(--accentdark);
    font-weight: 700;
    font-size: large;
    cursor: pointer;
}


.contact form .row-head {
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    align-items: baseline;
    margin-bottom: 10px;
}
.contact form .row {
    margin-bottom:30px ;

}
.contact form .row-head label{
    display: block;
    color: var(--accentyellow);
    font-weight: 700;
    /* margin-bottom: 5px; */
    /* background-color: royalblue; */
    height: 20px;
    text-transform: uppercase;
    margin-right: 10px;
}
.contact form .row-head small{
    color:red;
    /* padding-left: 10px; */
    display: block;
    /* background-color: rosybrown; */
    height: 20px;
}
.contact form div input[type="text"],input[type="email"],textarea{
    width: 100%;
    display: block;
    padding: 10px 10px;
    background-color: #232627;
    border:none;
    border-radius: 5px;
    color:#ccc;
}
.contact form div textarea{
    height: 150px;
    resize: vertical;
}

.contact form div input[type="submit"]{
    background-color: var(--accentyellow);
    width: 150px;
    height: 30px;
    border: none;
    outline: none;
    border-radius: 3px;
    color:var(--accentdark);
    font-weight: 700;
    font-size: large;
    cursor: pointer;
}


.contact .sending{
    margin-top: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contact .sending div:nth-of-type(1){
    font-size: 4rem;
    color: var(--accentyellow);
}
.contact .sending div:nth-of-type(2){
    letter-spacing: 0.5rem;
    font-weight: 700;
    font-size: large;
    margin-left: 25px;
}


@media screen and (max-width:800px){
    .contact .sent{
        margin-top: 40%;
        font-size: 1.2rem;
        /* background-color: royalblue; */
    }
    .contact .sending{
        margin-top: 35%;
    }
    
  }