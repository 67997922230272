.skills{
    /* background-color: #3e444b; */
    border: 1px solid #3e444b;
    padding: 10px;
    margin-top: 40px;
    background-color: #282c34;

}

.skills .s-row{
    display: flex;
    justify-content: space-between;
    font-size: small;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #3e444b;
    padding-left: 15px;
    padding-right: 15px;
}
.s-row:nth-last-child(1){
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: none !important;
    /* background-color: yellow; */
}

.skills .s-row .s-col1{
    width: 40%;
    padding-top:6px ;
    /* border-left: 3px solid #ffd23e; */

}
.skills .s-row .s-col1 span{
    padding: 5px;
    color: #ffd23e;
    font-weight: 700;
    /* color: black; */
    border-radius: 3px;
    font-size: medium;
}
.skills .s-row .s-col2{
    width: 55%;
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    
}
.skills .s-row .s-col2 span{
    background-color: var(--addressbar);
    margin: 3px 3px;
    padding: 3px 6px;
    border-radius: 3px;
    background-color: #ccc;
    color: var(--accentdark);
    font-weight: 700;
}

@media screen and (max-width:800px){
    .skills .s-row{
        display: block;
    }
    .skills .s-row .s-col1{
        width: 100%;
        margin-bottom: 20px;
    }
    .skills .s-row .s-col2{
        width: 100%;
        /* border: 1px solid red; */
        display: flex;
        flex-wrap: wrap;
    }
  }