.window-title-bar{
    background-color: rgb(52, 53, 66);
    background-color: var(--titlebar);
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    height: 25px;
}

.window-title-bar .action-buttons {
  padding-left: 10px;
  padding-bottom: 5px;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.window-title-bar .action-buttons button{
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: none;
  stroke: none;
  outline: none;
  border: none;
  margin-right: 3px;
}

.window-title-bar .action-buttons button:nth-of-type(3){
  background: #8CE99A;
}
.window-title-bar .action-buttons button:nth-of-type(2){
  background: #FFE066;
}
.window-title-bar .action-buttons button:nth-of-type(1){
  background: #FFA8A8;
}